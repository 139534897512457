<template>
	<div>
		<div class="navbar">
			<i v-if="iscollapse" class="el-icon-s-unfold hamburger" @click="iscollapseClick()"></i>
			<i v-else class="el-icon-s-fold hamburger" @click="iscollapseClick()"></i>
			<!--<el-button size="small" @click="addTab(editableTabsValue)">
				add tab
			</el-button>-->

			<!--<transition name="fade" mode="out-in">
				<p class="no-redirect">
					<el-link class="home" :underline="false">首 页</el-link>/<span>{{routerNametitle}}</span>
				</p>
			</transition>-->
			<!--<img class="logoimg" src="../../common/images/avatar.ecba1844.gif" />-->
			<div class="right-menu">
				<div class="UserBox">
					<p class="username nikename">{{nikname}}</p>
					<p class="username"></p>
				</div>

				<!--<img :src="`${imgUrl}${userImgurl}`" class="headportrait" alt="" />-->
				<!--<img :src="getImgUrl()" class="headportrait" alt="" />-->
				<el-image class="headportrait" :src="getImgUrl()" :preview-src-list="[getImgUrl()]" :z-index="4000"></el-image>

				<el-dropdown>
					<span class="el-dropdown-link">
        				<i class="el-icon-arrow-down el-icon--right"></i>
      				</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item icon="el-icon-refresh-right" @click.native="logout">退出</el-dropdown-item>
						<!-- <el-dropdown-item icon="el-icon-edit" @click.native="Modifyinformation">修改信息</el-dropdown-item> -->
						<!-- <el-dropdown-item icon="el-icon-edit" @click.native="resetpassword">修改密码</el-dropdown-item> -->
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div class="tags-view-container">
			<el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click="tabClick">
				<el-tab-pane v-for="(item, index) in editableTabs" :key="item.Path" :label="item.MenuName" :name="item.Path">
					{{item.MenuName}}
				</el-tab-pane>
			</el-tabs>
		</div>
		<changepassword v-if="dialogVisible" @getcancel="Getcancel"></changepassword>
		<bindingdepartmentdialog v-if="bdialogVisible == true" :dialogdata="dialogData" @bgetcancel="BGetcancel"></bindingdepartmentdialog>

	</div>

</template>

<script>
	import bindingdepartmentdialog from './BindingDepartmentdialog.vue';
	import changepassword from './ChangePassword.vue';
	import {} from "@/api/api";
	export default {
		name: '',
		components: {
			changepassword,
			bindingdepartmentdialog
		},
		props: {},
		data() {
			let userinformation = JSON.parse(localStorage.getItem('Userinformation'))
			return {
				dialogVisible: false,
				bdialogVisible: false,
				iscollapse: false,
				editableTabsValue: '', //选中路由
				editableTabs: [],
				tabIndex: 2,
				routerNametitle: '',
				activelen: 0,
				nikname: userinformation.username,
				imgUrl: '',
				userImgurl: '',
				//OrgName: userinformation.OrgName
			}
		},
		computed: {
			togglesideBar() { //当前navbar数组；
				return this.$store.state.navBar;
			},
			currenthref() {
				return this.$store.state.currenthref;
			},
			nikName() {
				return this.$store.state.nickname;
			},
			picFun() {
				return this.$store.state.pic;

			}
		},
		watch: {
			nikName(newVal, oldVal) {
				this.nikname = newVal
			},
			picFun(newVal, oldVal) {
				this.userImgurl = newVal;

			},

			togglesideBar: {
				handler(val) {
					this.editableTabs = val;
					//this.routerNametitle = this.editableTabs[this.editableTabs.length - 1].name

				},
				deep: true
			},
			currenthref(newVal, oldVal) {
				this.editableTabsValue = newVal;
				this.editableTabs.forEach((item, i) => {
					if(item.Path == newVal) {
						this.routerNametitle = item.MenuName;
					}
				})
			},
			$route(to, from) {

			}
		},
		created() {

			if(localStorage.getItem('menukey')) {
				this.editableTabsValue = localStorage.getItem('menukey')
			}

		},
		beforeDestroy() {},
		mounted() {
			//this.userImgurl = this.$store.state.pic

			if(localStorage.getItem('Lastmenu')) {

				let lastmenu = JSON.parse(localStorage.getItem('Lastmenu'))
				this.$router.replace(lastmenu.Path)

        this.editableTabs = [lastmenu]
			}

		},
		methods: {
			getImgUrl() {

				return this.imgUrl + this.$store.state.pic;
			},
			BGetcancel() {
				this.bdialogVisible = false;

			},
			Modifyinformation(row) {
				this.bdialogVisible = true;
				this.dialogData = [row]
			},
			Getcancel() {
				this.dialogVisible = false;

			},
			resetpassword() { //修改密码
				this.dialogVisible = true;

			},
			logout(e) {

				//window.localStorage.clear();
				this.$router.replace('/siginin')
				window.localStorage.clear();
			},
			iscollapseClick() {
				this.iscollapse = !this.iscollapse;
				this.$store.commit('isCollapse', this.iscollapse);

			},
			tabClick(evt) {

				this.$store.commit('currenthRef', evt.name);
				this.$router.replace(evt.name)
				this.routerNametitle = evt.label;
				if(this.editableTabs.length != 1 && evt.label != '首页') {
					localStorage.setItem('Lastmenu', JSON.stringify({
						Path: evt.name,
						MenuName: evt.label
					}));
				} else {
					localStorage.setItem('Lastmenu', '')
				}
				localStorage.setItem('menukey', evt.name);

			},
			removeTab(targetName) {

				this.editableTabs.forEach((item, i) => {
					if(item.Path == targetName) {

						this.editableTabs.splice(i, 1)
					}
				})

				if(this.editableTabs.length == 0) {
					this.$router.replace('indexpage');
					localStorage.setItem('Lastmenu', '')
					localStorage.setItem('menukey', 'indexpage');

				} else {
					if(targetName == this.editableTabsValue) {

						localStorage.setItem('menukey', this.editableTabs[this.editableTabs.length - 1].Path);
						this.$router.replace(this.editableTabs[this.editableTabs.length - 1].Path)
						this.editableTabsValue = this.editableTabs[this.editableTabs.length - 1].Path
						this.routerNametitle = this.editableTabs[this.editableTabs.length - 1].MenuName;

						this.$store.commit('currenthRef', this.editableTabs[this.editableTabs.length - 1].Path); //2022.3.11优化 （删除nav后 再次点击左侧刚删除的菜单nav 不高亮）
						if(this.editableTabs.length != 1) {
							localStorage.setItem('Lastmenu', JSON.stringify({
								Path: this.editableTabs[this.editableTabs.length - 1].Path,
								MenuName: this.editableTabs[this.editableTabs.length - 1].MenuName
							}));
						}
						/*else{
													localStorage.setItem('Lastmenu', '')
												}*/

						/*if(this.editableTabs.length != 1) {
							localStorage.setItem('Lastmenu', JSON.stringify({
								Path: this.editableTabs[this.editableTabs.length - 1].Path,
								MenuName: this.editableTabs[this.editableTabs.length - 1].MenuName
							}));
						} else {
							localStorage.setItem('Lastmenu', '')
						}*/

					}
				}

			}
		}

	}
</script>

<style scoped>
	>>>.el-icon-arrow-left {
		font-size: 18px;
		line-height: 30px;
	}

	>>>.el-icon-arrow-right {
		font-size: 18px;
		line-height: 30px;
	}

	.navbar {
		width: 100%;
		height: 50px;
		overflow: hidden;
		position: relative;
		background: #fff;
		-webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
		-moz-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
		box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
	}

	.tags-view-container {
		height: 34px;
		width: 100%;
		background: #fff;
		border-bottom: 1px solid #d8dce5;
		-webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
		-moz-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
		overflow: hidden;
	}

	.hamburger {
		width: 20px;
		height: 50px;
		font-size: 20px;
		color: #000000;
		line-height: 50px;
		margin-left: 15px;
		float: left;
	}

	>>>.el-tabs__content {
		display: none;
	}

	>>>.el-tabs__header {
		margin: 0px;
	}

	>>>.el-tabs__item {
		height: 25px;
		line-height: 25px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	>>>.el-tabs--card>.el-tabs__header .el-tabs__nav {
		margin-top: 6px;
	}

	>>>.el-tabs__nav {
		border: none !important;
	}

	>>>.el-tabs__item {
		border-left: none !important;
		border-top: none !important;
		border-right: 1px solid #E4E7ED;
	}

	>>>.el-tabs--card>.el-tabs__header {
		border-bottom: none !important;
	}

	>>>.el-tabs__item:last-child {
		border-right: none !important;
	}

	>>>.el-tabs__nav-next,
	.el-tabs__nav-prev {
		line-height: 35px;
		font-size: 20px;
	}

	>>>.el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable {
		background: #42b983;
		color: #fff;
		border-radius: 2px;
	}

	.no-redirect {
		color: #97a8be;
		cursor: text;
		line-height: 50px;
		float: left;
		font-size: 14px;
		margin-left: 10px;
	}

	.home {
		margin-left: 5px;
		margin-right: 5px;
	}

	.no-redirect span {
		margin-left: 5px;
		margin-right: 5px;
	}

	.fade-enter {
		opacity: 0;
	}

	.fade-leave {
		opacity: 1;
	}

	.fade-enter-active {
		transition: opacity .3s;
	}

	.fade-leave-active {
		opacity: 0;
		transition: opacity .3s;
	}

	.right-menu {
		float: right;
		height: 50px;
	}

	.headportrait {
		display: block;
		float: left;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-top: 5px;
		margin-right: 10px;
		border: 1px solid #e5e5e5;
		padding: 5px;
	}

	.UserBox {
		float: left;
		padding: 0px 10px;
		height: 50px;
	}

	.username {
		line-height: 20px;
		color: #606266;
		font-size: 12px;
		text-align: center;
	}

	.nikename {
		font-size: 16px;
		color: #333;
		font-weight: bold;
	}

	.username:nth-child(1) {
		margin-top: 7px;
	}

	.dropdowns {
		float: left;
	}

	>>>.el-dropdown {
		height: 50px;
		line-height: 50px;
		margin-right: 10px;
	}
	/*>>>.el-tabs__item:first-child span {
		display: none !important;
	}*/
	/*>>>.el-tabs__item:first-child .el-icon-close:hover{
		display: none;
	}*/

	.logoimg {
		height: 35px;
		margin-top: 7px;
		margin-left: 10px;
	}

	>>>.el-tabs__nav-next,
	.el-tabs__nav-prev {
		font-size: 20px;
	}
</style>
