<template>
	<div>
		<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-rulesFrom" style="height: 100%">
			<el-dialog v-dialogDrag title="修改密码" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-press-escape='false' :close-on-click-modal="false">
				<el-row>
					<el-col :span="24">
						<el-form-item type="type" label="原密码" prop="Opassword">
							<el-input :disabled="disableds" show-password v-model.trim="ruleForm.Opassword" placeholder="原密码" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item type="type" label="新密码" prop="newpassword">
							<el-input :disabled="disableds" show-password v-model.trim="ruleForm.newpassword" placeholder="新密码" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item type="type" label="确认密码" prop="repassword">
							<el-input :disabled="disableds" show-password v-model.trim="ruleForm.repassword" placeholder="确认密码" />
						</el-form-item>
					</el-col>
				</el-row>

				<span slot="footer" class="dialog-footer">
			        <el-button v-if="!disableds" type="primary" @click="deteRmine('ruleForm')">确 定</el-button>
			        <el-button @click="cancel">关 闭</el-button>
		        </span>
			</el-dialog>
		</el-form>
	</div>
</template>

<script>
	import {  } from "@/api/api";

	export default {
		name: '',
		components: {

		},
		props: {},
		data() {
			let validatePass2 = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请再次输入密码'))
					// password 是表单上绑定的字段
				} else if(value !== this.ruleForm.newpassword) {
					callback(new Error('两次输入密码不一致!'))
				} else {
					callback()
				}
			}
			return {
				dialogVisible: true,
				disableds: false,
				row: '',
				ruleForm: {
					Opassword: '',
					newpassword: '',
					repassword: '',
				},
				rules: {
					Opassword: [{
						required: true,
						message: "请输入必填项",
						trigger: "blur"
					}, ],
					newpassword: [{
						required: true,
						message: "请输入必填项",
						trigger: "blur"
					}, ],
					repassword: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}],
				},

			}
		},
		computed: {},
		watch: {},
		created() {},
		beforeDestroy() {},
		mounted() {

		},
		methods: {
			deteRmine(fromName) {
				this.$refs[fromName].validate(valid => {
					if(valid) {
						let params = {
							
							old_passWord: this.ruleForm.Opassword,
							passWord: this.ruleForm.newpassword
						}

						EditPassWord(params).then((res) => {

							if(res.errorCode == 0) {
								this.$message({
									message: '修改成功！请重新登录',
									type: 'success'
								});
								this.$router.replace('/siginin')
								window.localStorage.clear();
							} else {
								this.$message.error(res.data);
							}

						}).catch((err) => {
							this.$message.error('修改失败！请联系管理员');

						})
						/**/
					} else {
						return false
					}

				})
			},
			cancel() {
				this.dialogVisible = false
				this.$emit('getcancel')
			},

			handleClose() {
				this.dialogVisible = false
				this.$emit('getcancel')
			},

		}
	}
</script>

<style scoped>
	.selectClass {
		width: 100%;
	}
	
	::v-deep .el-dialog {
		width: 700px !important;
		border-radius: 8px;
		overflow: hidden;
	}
	
	::v-deep .el-dialog__body {
		max-height: 500px;
		overflow: auto;
	}
</style>