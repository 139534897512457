<template>
	<div class='has-logo sidebar-container'>
		<div class="sidebar-logo-container">
			<transition name="el-zoom-in-center">
				<h1 v-if="!isCollapse" class="sidebar-title">{{projectname}}</h1>
			</transition>
			<transition name="el-zoom-in-center">

				<!-- <el-image class="iconimg" :src="getImgUrl()" :preview-src-list="[getImgUrl()]" :z-index="4000"></el-image> -->

				<!--<img v-if="isCollapse" class="iconimg" src="../../common/images/avatar.ecba1844.gif" alt="" />-->
			</transition>
		</div>
		<div class="el-scrollbar">
			<div class="menu-container">
				<el-menu :collapse="isCollapse" :default-active="savepath" class="el-menu-vertical-demo" background-color="#304156" text-color="#fff" active-text-color="#409eff">
					<div v-for="(item,i) in newMenulist">
						<el-submenu :index="item.id+''" v-if="item.children.length!=0" :key="i" class="oneClass">
							<!--一级级菜单-->
							<template slot="title" class="oneClass">
								<i class="iconfonts " :class="item.icon"></i>
								<span>{{item.name}}</span>
							</template>
							<div v-for="(itemk,k) in item.children">
								<el-submenu v-if="itemk.children.length!=0" :index="itemk.id+''" class="twoClass">
									<!--二级级菜单-->
									<template slot="title">
										<!--<i class="towiconfont" :class="itemk.icon"></i> -->
										{{itemk.name}}
									</template>
									<!--三级菜单-->
									<el-menu-item v-for="(itemj,j) in itemk.children" :index="itemj.path" :key="j" @click="menuhref(itemj,j)" class="threeClass">
										{{itemj.name}}
									</el-menu-item>
								</el-submenu>
								<el-menu-item v-if="itemk.children.length==0" :index="itemk.path" @click="menuhref(itemk,i)" class="twoClass">
									<!--二级级菜单-->
									<!--<i class="towiconfont" :class="itemk.icon"></i> -->
									{{itemk.name}}
								</el-menu-item>
							</div>
						</el-submenu>
						<el-menu-item :index="item.path" v-if="item.children.length==0" :key="i" @click="menuhref(item,i)" class="oneClass">
							<!--一级级菜单-->
							<i class="iconfonts " :class="item.icon"></i>
							<span slot="title">{{item.name}}</span>
						</el-menu-item>

					</div>
				</el-menu>

			</div>
		</div>

	</div>
</template>

<script>
	import menulist from '@/common/js/menujson.js';
	//import { GetUserPromissList } from "@/api/api";

	export default {

		name: '',
		components: {

		},
		props: {},
		data() {

			return {
				defaultActivePath: '1',
				newMenulist: [],
				savepath: '',
				projectname: '工地圈子',
				menuactivelist: [],
				isCollapse: false,
				imgUrl: VUE_WEB_URL.imagesUrl,

			}
		},
		computed: {
			iscollaPse() {
				return this.$store.state.iscollapse;
			}
		},
		watch: {
			iscollaPse(newVal, oldVal) {
				this.isCollapse = newVal
			},
			$route(to, from) {
				this.savepath = to.path.split('/')[1]
			}
		},
		created() {
			this.savepath = localStorage.getItem('menukey')

		},
		beforeDestroy() {

		},
		mounted(){
        if(localStorage.getItem('Userinformation') != null){
          this.newMenulist = menulist.jg
        }

			/* if(localStorage.getItem('Userinformation')!='null'&&localStorage.getItem('Userinformation')!='null'!=null) {
				let userinformation = JSON.parse(localStorage.getItem('Userinformation'))


				this.newMenulist = this.toTree(userinformation.module)
				//console.log(this.newMenulist)
				if(localStorage.getItem('Lastmenu') != null && localStorage.getItem('Lastmenu') != '') {
					this.menuactivelist.push(JSON.parse(localStorage.getItem('Lastmenu')))
					this.$store.commit('toggleSideBar', this.menuactivelist);

				}
			} */

		},
		methods: {
			getImgUrl() {

				return this.imgUrl + this.$store.state.pic;
			},
			menuhref(data) {
				let light = data.path
				localStorage.setItem('menukey', light);
				this.savepath = light;
				this.$router.replace(data.path)

				let navbarlist = {
					Path: data.path,
					MenuName: data.name
				}
				let flag = true;

				this.menuactivelist.forEach(item => {
					if(item.Path === data.path) { // 对象里的唯一标识id
						flag = false;
					}
				})
				if(flag) {
					this.menuactivelist.push(navbarlist)
				}
				this.$store.commit('toggleSideBar', this.menuactivelist);
				this.$store.commit('currenthRef', data.path);
				localStorage.setItem('Lastmenu', JSON.stringify(navbarlist));

			},
			toTree(data) {
				const result = []
				const map = {}
				if(!Array.isArray(data)) {

					return result
				}
				data.forEach((item) => {
					item.children = []
				})
				data.forEach((item) => {
					map[item['id']] = item
				})
				data.forEach((item) => {
					const parent = map[item['parent_id']]
					if(parent) {

						if(!parent.children) {
							parent.children = []
						}

						if(!parent.level) {
							parent.level = 1
						}
						item.level = parent.level + 1
						parent.children.push(item)
					} else {

						result.push(item)

					}
				})
				return result
			},

			menuDataTree(menuData) {
				let content = menuData; //未处理的菜单数据
				//给每个未处理的数据添加children
				for(var a = 0; a < content.length; a++) {
					content[a].children = []
				}
				//拿出最高层级的元素----pid数值最小则层级最高，直接取0
				for(var b = 0; b < content.length; b++) {
					if(content[b].parent_id === 0) {
						//this.newlist = content.splice(b, 1)
						this.newMenulist.push(content[b])

					}
				}

				this.deepSort(this.newMenulist, content)
			},
			deepSort(list, content) {
				var content1 = []
				for(var m = 0; m < list.length; m++) {

					for(var n = 0; n < content.length; n++) {

						if(list[m].id == content[n].parent_id) {

							list[m].children.push(content[n])

						} else {

							content1.push(content[n])

						}
					}
				}
				for(var o = 0; o < list.length; o++) {
					this.deepSort(list[o].children, content1)
				}

			},
		}

	}
</script>

<style scoped>
	.sidebar-container {
		width: 210px;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		background: #304156;
	}

	.sidebar-logo-container {
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #2b2f3a;
		text-align: center;
		overflow: hidden;
	}

	.el-scrollbar {
		height: calc(100% - 50px);
		height: -webkit-calc(100% - 50px);
		height: -moz-calc(100% - 50px);
		overflow: hidden;
		position: relative;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.menu-container {
		position: absolute;
		left: 0px;
		right: -20px;
		top: 0px;
		bottom: 0px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	>>>.el-menu {
		border-right: none !important;
	}
	/*>>>.el-submenu__icon-arrow {
		right: 30px;
	}*/

	>>>.collapse-transition {
		-webkit-transition: 0s height, 0s padding-top, 0s padding-bottom;
		transition: 0s height, 0s padding-top, 0s padding-bottom
	}

	>>>.horizontal-collapse-transition {
		-webkit-transition: 0s width, 0s padding-left, 0s padding-right;
		transition: 0s width, 0s padding-left, 0s padding-right
	}

	>>>.horizontal-collapse-transition .el-submenu__title .el-submenu__icon-arrow {
		-webkit-transition: 0s;
		transition: 0s;
		opacity: 0
	}
	/*隐藏文字*/

	.el-menu--collapse .el-submenu__title span {
		display: none;
	}
	/*隐藏 > */

	.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
		display: none;
	}

	.iconfonts {
		font-size: 20px;
		color: #fff;
		margin-right: 12px;
	}

	.sidebar-title {
		display: inline-block;
		margin: 0;
		color: #fff;
		font-weight: 600;
		line-height: 50px;
		font-size: 14px;
		font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
		vertical-align: middle;
	}

	.iconimg {
		display: block;
		width: 30px;
		height: 30px;
		margin-left: 14px;
		border-radius: 6px;
		margin-top: 8px;
	}

	.towiconfont {
		font-size: 16px;
		color: #fff;
		margin-right: 6px;
	}

	.twoClass {
		background: #475b74 !important;
		/*不带三级菜单的二级背景色*/
	}

	.threeClass {
		background: #525b66 !important;
		/*三级背景色*/
	}

	>>>.el-menu-item:focus,
	.el-menu-item:hover {
		background: #5e6c7e !important;
	}

	.twoClass>>>.el-submenu__title {
		/*带三级菜单的二级背景色*/
		background: #475b74 !important;
	}
</style>
