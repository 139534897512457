<template>
	<div class="app-wrapper">
		<menuBar></menuBar>
		<div class="main-container" :class="isCollapse==false?'rcontainer-active':'lcontainer-active'">
			<navbar></navbar>
			<div class="view-container">
				<transition name="fade" mode="out-in">
					<!--<keep-alive :include="cacheList">--> <!--是否需要缓存-->
						<!--:include="cacheList"-->
						<!--:include="该路由组件name”-->
						<router-view></router-view>
					<!--</keep-alive>-->
				</transition>

			</div>
		</div>
	</div>
</template>
<script>
	import menuBar from './components/menu.vue';
	import navbar from './components/navBar.vue'
	export default {
		name: '',
		components: {
			menuBar,
			navbar
		},
		props: {},
		data() {
			return {
				isCollapse: false,
			}
		},
		computed: {
			iscollaPse() {
				return this.$store.state.iscollapse;

			},
			cacheList() {
				let cacheList = []
				
				for(let i = 0; i < this.$store.state.navBar.length; i++) {
					cacheList.push(this.$store.state.navBar[i].Path)
				}
				return cacheList

			}
		},
		watch: {
			iscollaPse(newVal, oldVal) {
				this.isCollapse = newVal
			}
		},
		created() {},
		beforeDestroy() {},
		mounted() {},
		methods: {}
	}
</script>
<style scoped>
	.app-wrapper {
		width: 100%;
		height: 100%;
		background: #eceff5;
		position: relative;
		/*background: url(../common/images/bg.jpeg) no-repeat center;*/
	}
	
	.main-container {
		position: absolute;
		left: 210px;
		top: 0px;
		right: 0px;
		bottom: 0px;
	}
	
	.rcontainer-active {
		left: 210px;
		transition: left 0.2s;
		-moz-transition: left 0.1s;
		-webkit-transition: left 0.4s;
		-o-transition: left 0.1s;
	}
	
	.lcontainer-active {
		left: 64px;
		transition: left 0.1s;
		-moz-transition: left 0.1s;
		-webkit-transition: left 0.4s;
		-o-transition: left 0.1s;
	}
	
	.view-container {
		width: 100%;
		height: calc(100% - 85px);
		background: #eceff5;
		overflow: auto;
	}
	
	.fade-enter {
		opacity: 0;
	}
	
	.fade-leave {
		opacity: 1;
	}
	
	.fade-enter-active {
		transition: opacity .2s;
	}
	
	.fade-leave-active {
		opacity: 0;
		transition: opacity .2s;
	}
</style>