let jg = [{
    name: '群管理',
    path: 'indexpage',
    icon: 'el-icon-setting',
    children: [{
      name: '群管理',
      path: 'groupmanagement',
      icon: 'el-icon-setting',
      children: [],
      id: 8
    }],
    id: 1
  },
  {
    name: '招工信息录入',
    path: 'recruitmentInfo',
    icon: 'el-icon-setting',
    children: [],
    id: 3
  },

  {
    name: '工种类型管理',
    path: 'typeofwork',
  	 icon: 'el-icon-setting',
    children: [],
    id: 30
  },
  {
    name: '提现列表',
    path: 'withdrawal',
    icon: 'el-icon-setting',
    children: [],
    id: 31
  },
  {
    name: '会员注册',
    path: 'memberregistration',
    icon: 'el-icon-setting',
    children: [],
    id: 4
  },
  {
    name: '消费记录',
    path: 'recordsofcon',
    icon: 'el-icon-setting',
    children: [],
    id: 5
  },
  {
    name: '加群记录',
    path: 'Clusteringrecord',
    icon: 'el-icon-setting',
    children: [],
    id: 6
  },
  {
    name: '推广大厅',
    path: 'promotionhall',
    icon: 'el-icon-setting',
    children: [],
    id: 6
  },
{
    name: '委托找活',
    path: 'entrustedwork',
    icon: 'el-icon-setting',
    children: [],
    id: 33
  },
  {
    name: '工厂招工',
    path: '',
    icon: 'el-icon-setting',
    children: [{
        name: '工厂管理',
        path: 'factorymanagement',
        icon: 'el-icon-setting',
        children: [],
        id: 9
      },
      {
        name: '报名记录',
        path: 'registrationrecord',
        icon: 'el-icon-setting',
        children: [],
        id: 9
      },


    ],
    id: 7
  },

];

export default {
  jg,

}
