<template>
	<div>
		<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-rulesFrom" style="height: 100%">
			<el-dialog v-dialogDrag title="修改信息" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-press-escape='false' :close-on-click-modal="false">

				<el-row>
					<el-col :span="24">
						<el-form-item type="type" label="手机号" prop="mobile">
							<el-input :disabled="disableds" v-model="ruleForm.mobile" placeholder="手机号" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item type="type" label="邮箱" prop="email">
							<el-input :disabled="disableds" v-model="ruleForm.email" placeholder="邮箱" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item type="type" label="用户昵称" prop="userName">
							<el-input :disabled="disableds" v-model="ruleForm.userName" placeholder="用户昵称" />
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="24">
						<el-form-item type="type" label="管理员头像" prop="UserHead" class="frombr">
							<el-image class="HximgBg" :src="getImgUrl()" v-if="pic != '' && pic != null  && fileList.length==0" :preview-src-list="[getImgUrl()]" :z-index="4000"></el-image>
							
							<!--<div class="imgBox" v-if="pic != '' && pic != null  && fileList.length==0">

								<img :src="getImgUrl()" alt="" class="Img" />

							</div>-->
							<el-upload ref="upload" accept=".jpg,.png" action="" :on-preview="handlePictureCardPreview" :auto-upload="false" list-type="picture-card" :on-change="filechange" :on-remove="handleRemove">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
								
							</el-upload>
							<el-dialog v-if="pic == ''" v-dialogDrag title="预览" append-to-body :visible.sync="imgDialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-form-item>
					</el-col>
				</el-row>

				<span slot="footer" class="dialog-footer">
			        <el-button v-if="!disableds" type="primary" @click="deteRmine('ruleForm')">确 定</el-button>
			        <el-button @click="cancel">关 闭</el-button>
		        </span>
			</el-dialog>
		</el-form>
	</div>
</template>

<script>

	import axios from 'axios'
	
	import {  } from "@/api/api";
	
	export default {
		name: '',
		components: {

		},
		props: {
			dialogdata: Array
		},
		data() {

			
			return {
				dialogVisible: true,
				disableds: false,
				imgDialogVisible: false,
				row: '',
				dialogState: '',
				ruleForm: {
					mobile: '',
					email: '',
					userName: '',
				},
				rules: {
					mobile: [{
							required: true,
							message: '请输入必选项',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '请输入有效的手机号',
							trigger: 'blur'
						},
					],
					email: [{
						required: false,
						message: '请输入必选项',
						trigger: 'blur'
					}, ],
					userName: [{
						required: true,
						message: '请输入必选项',
						trigger: 'blur'
					}, ],
				},
				Departmentlist: [],
				rolelist: [],
				dialogImageUrl: '',
				pic: '',
				fileList: [],
				imgUrl: VUE_WEB_URL.imagesUrl,
				userinformation:JSON.parse(localStorage.getItem('Userinformation'))
			}
		},
		computed: {},
		watch: {},
		created() {},
		beforeDestroy() {},
		mounted() {

		
			this.ruleForm.mobile = this.userinformation.mobile
			this.ruleForm.email = this.userinformation.email
			this.ruleForm.userName = this.userinformation.nickName
			this.pic = this.userinformation.pic
		},
		methods: {
			getImgUrl() {
				return this.imgUrl + this.pic;
			},

			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.imgDialogVisible = true;
			},
			handleRemove(file, fileList) { // 删除文件
				this.hideUpload = false;

				this.fileList = []
			},
			filechange(file, list) {
				if(file.raw.name.split('.')[file.raw.name.split('.').length - 1] == 'png' || file.raw.name.split('.')[file.raw.name.split('.').length - 1] == 'jpg') {
					this.fileList = [file.raw]
					if(list.length > 1) {
						list.splice(0, 1);
					}
				} else {
					this.$message.error('上传头像图片只能是 png/jpg 格式!');
					this.$refs.upload.clearFiles()
					return;
				}
			},
			deteRmine(fromName) {
				
				
				
				
				

				this.$refs[fromName].validate(valid => {
					if(valid) {

						let formData = new FormData();
						let config = {
							headers: {
								Authorization: localStorage.getItem('Token')
							}
						}

						if(this.dialogState != 'edit') {

							formData.append('file', this.fileList[0])
							formData.append('nickName', this.ruleForm.userName)
							formData.append('mobile', this.ruleForm.mobile)
							formData.append('email', this.ruleForm.email)
							
							EditMyInfo(formData).then((res)=>{
								if(res.errorCode == 0) {
									this.$message({
										message: '修改成功！',
										type: 'success'
									});

									this.userinformation.nickName = res.data.nickName;
									this.userinformation.email = res.data.email;
									this.userinformation.mobile = res.data.mobile;
									if(res.data.imageHead != null){
										this.userinformation.pic = res.data.imageHead
										this.$store.commit('picfun', res.data.imageHead)
									}

									this.$store.commit('nickName', res.data.nickName)
									
									localStorage.setItem('Userinformation', JSON.stringify(this.userinformation));
									this.$emit('bgetcancel')
								} else {
									this.$message.error(res.data.msg);
								}
								
								
							}).catch(() => {
								this.$message.error('修改失败！');
							});
							
							
							/*axios.post(VUE_WEB_URL.baseUrl + '/webadmin/adminuser/EditMyInfo', formData, config).then((res) => {
								if(res.data.errorCode == 0) {
									this.$message({
										message: '修改成功！',
										type: 'success'
									});

									userinformation.nickName = res.data.data.nickName;
									userinformation.email = res.data.data.email;
									userinformation.mobile = res.data.data.mobile;
									userinformation.pic = res.data.data.imageHead
									this.$store.commit('nickName', res.data.data.nickName)
									this.$store.commit('picfun', res.data.data.imageHead)
									localStorage.setItem('Userinformation', JSON.stringify(userinformation));
									this.$emit('bgetcancel')
								} else {
									this.$message.error(res.data.data.msg);
								}

							}).catch(() => {
								this.$message.error('修改失败！');
							});*/
						} else {

						}

					} else {
						return false
					}

				})
			},
			cancel() {
				this.dialogVisible = false
				this.$emit('bgetcancel')
			},

			handleClose() {
				this.dialogVisible = false
				this.$emit('bgetcancel')
			},

		}
	}
</script>

<style scoped>
	.HximgBg {
		width: 126px;
		height: 126px;
		float: left;
		margin-right: 10px;
		border-radius: 6px;
	}
	.selectClass {
		width: 100%;
	}
	
	::v-deep .el-dialog {
		width: 700px !important;
		border-radius: 8px;
		overflow: hidden;
	}
	
	::v-deep .el-dialog__body {
		max-height: 500px;
		overflow: auto;
	}
	
	.rowTops {
		position: relative;
	}
	
	.rowTopa {
		position: absolute;
		left: 0px;
		top: 40px;
		right: 0px;
		margin: auto;
		max-height: 300px;
		background: #fff;
		border: 1px solid #e3e3e3;
		z-index: 100;
		border-radius: 5px;
		overflow: auto;
		padding-bottom: 10px;
	}
	
	.iptclose {
		position: absolute;
		right: 10px;
		top: 0px;
		color: #606266;
		font-size: 16px;
		line-height: 40px;
		z-index: 1000;
		transition: color .15s linear;
	}
	
	.iptclose:hover {
		color: #5cb6ff !important;
	}
	
	.imgBox {
		width: 130px;
		height: 130px;
		position: relative;
		float: left;
		margin-right: 10px;
	}
	
	.Img {
		width: 130px;
		height: 130px;
	}
	
	.imgBox span {
		position: absolute;
		right: 5px;
		top: 5px;
		font-size: 16px;
		background: #919191;
		color: #ffffff;
		padding: 3px;
		border-radius: 2px;
	}
</style>